import * as React from 'react'
import Logo from '../utils/logo'
import ContactForm from '../utils/contactform'

import iconBootstrap from "./icon-bootstrap.svg"
import iconDev from "./icon-dev.svg"
import iconSecurity from "./icon-security.svg"
import iconLeadership from "./icon-leadership.svg"
import iconEmpireState from "./icon-empire-state.svg"
import iconFlorida from "./icon-florida.svg"
import Layout from "../components/index"

if (!process.env.API_ENDPOINT) {
  throw new Error('process.env.API_ENDPOINT is unset')
} else {
  var apiEndpoint = process.env.API_ENDPOINT
}

const OnePager = () => (
  <Layout>
    <div className="">
      <div className="banner hero is-medium is-info">
	{/* Navbar */}
	<div className="hero-head">
	  <div className="container navbar">
	    <div className="navbar-brand">
	      <a className="navbar-item" href="/"><Logo className="logo" /></a>
	    </div>
	  </div>
	</div>

	{/* Tagline */}
	<div className="hero-body">
	  <div className="container tagline">   
	    <h1>Our tagline is your business</h1>
	    <p>
	      For any size business that needs to produce, HeavySet is a team of developers, security experts, and leaders that delivers.
	    </p>
	  </div>
	</div>
      </div>

      <section className="hero services">
	{/* Services */}
	<div className="hero-body">
	  <div className="container">
	    <div className="columns has-text-centered">
	      <div className="column">
		<img src={iconBootstrap}/>
		<h2>Bootstrap</h2>
		<p>Whether it's building an MVP or your first release, we'll help get your startup off the ground.</p>
	      </div>

	      <div className="column">
		<img src={iconDev}/>
		<h2>Development</h2>
		<p>We'll work with your team and your code to design and build the solutions you need.</p>
	      </div>

	      <div className="column">
		<img src={iconSecurity}/>
		<h2>Security</h2>
		<p>We can perform audits, pen testing, and even security training for your team.</p>
	      </div>

	      <div className="column">
		<img src={iconLeadership}/>
		<h2>Leadership</h2>
		<p>We can provide executive-level technology leadership to give your company a solid footing.</p>
	      </div>
	    </div>
	  </div>
	</div>
      </section>

      <section className="container summary has-text-justified">
	<div className="columns">
	  <div className="column is-7">
	    <p>
	      HeavySet is not a typical development shop. Our team has a deep technical background and long history
	      of building robust platforms. We've launched startups, worked with Fortune 500 companies, and
	      provided secure infrastructure for US presidential campaigns. We have designed and delivered
	      everything from boutique websites, to secure financial services, to globally distributed systems
	      processing billions of transactions a day.
	    </p>
	    <p>
	      We strive to understand all aspects of our client's needs and provide the most appropriate
	      solutions to meet them. If having a person perform a task manually rather than writing software to
	      solve the problem provides better business value, that's what we'll suggest. If we discover a
	      hitherto-unknown business problem we think you should solve with software for the best long-term value,
	      we'll make our best argument for writing more code. And if we think your problem is best solved with an
	      off-the-shelf solution, we'll be happy to help identify the right vendors.
	    </p>
	  </div>

	  <div className="column is-5">
	    <div className="partner is-clearfix">
	      <figure className="image is-96x96 is-pulled-left">
		<img src="https://gravatar.com/avatar/0f28a45bfaea2adf79e9f8b884516008.jpg?s=96"/>
	      </figure>
	      <div className="details is-pulled-left">
		<h3>Gabriel Gironda</h3>
		<div className="is-italic">Partner</div>
		<div>
		  <span className="icon">
		    <a href="https://linkedin.com/in/gabrielgironda"><i className="fa fa-linkedin"></i></a>
		  </span>
		  <span className="icon">
		    <a href="https://github.com/gabrielg"><i className="fa fa-github"></i></a>
		  </span>
		</div>
	      </div>
	    </div>

	    <div className="partner is-clearfix">
	      <figure className="image is-96x96 is-pulled-left">
		<img src="https://gravatar.com/avatar/50fe515aeb2febdca413eeff9f43518f.jpg?s=96"/>
	      </figure>
	      <div className="details is-pulled-left">
		<h3>Gus Knowlden</h3>
		<div className="is-italic">Partner</div>
		<div>
		  <span className="icon">
		    <a href="https://linkedin.com/in/jaknowlden"><i className="fa fa-linkedin"></i></a>
		  </span>
		  <span className="icon">
		    <a href="https://github.com/gus"><i className="fa fa-github"></i></a>
		  </span>
		</div>
	      </div>
	    </div>

	    <div className="partner is-clearfix">
	      <figure className="image is-96x96 is-pulled-left">
		<img src="https://en.gravatar.com/userimage/4478428/d6d2daa94f0eca8650ae7f8a9217690e.jpeg?size=96"/>
	      </figure>
	      <div className="details is-pulled-left">
		<h3>Jenny Rakoczy</h3>
		<div className="is-italic">Partner</div>
		<div>
		  <span className="icon">
		    <a href="https://linkedin.com/in/jenniferrakoczy"><i className="fa fa-linkedin"></i></a>
		  </span>
		  <span className="icon">
		    <a href="https://github.com/toothrot"><i className="fa fa-github"></i></a>
		  </span>
		</div>
	      </div>
	    </div>
	  </div>
	</div>
      </section>

      <section className="hero is-dark contact">
	{/* Contact */}
	<a name="contactus"/>
	<div className="hero-body">
	  <div className="container">
	    <div className="columns">
	      <div className="column is-2 locations">
		<div className="contactus-h1 is-uppercase">Locations</div>
		<div className="is-size-7">
		  <img src={iconEmpireState}/>
		  <span>New York City</span>
		</div>
		<div className="is-size-7">
		  <img src={iconFlorida}/>
		  <span>Jacksonville</span>
		</div>
	      </div>

	      <div className="column is-2 contact-methods">
		<div className="contactus-h1 is-uppercase">Contact Us</div>
		<div className="is-size-7">
		  <span className="icon">
		    <i className="fa fa-envelope"></i>
		  </span>
		  <a href="mailto:contact@heavyset.io">contact@heavyset.io</a>
		</div>
		<div className="is-size-7">
		  <span className="icon">
		    <i className="fa fa-phone"></i>
		  </span>
		  <a href="tel:1-929-324-7227">(929) 324-7227</a>
		</div>
	      </div>

	      <div className="column is-8">
		<div className="contactus-h1 is-uppercase">Message Us</div>
		<ContactForm
		  apiBaseURL={apiEndpoint}
		  inquiryPath="/inquiries" />
	      </div>
	    </div>
	  </div>
	</div>
      </section>

    </div>
  </Layout>
)

export default OnePager
