import * as React from 'react'
import classNames from 'classnames'

type ContactFormSubmitButtonProps = {
  isSubmitting: boolean,
  onClick: Function
};

type ContactFormSubmitButtonState = {
  isHovered: boolean,
  isActive: boolean
};

class ContactFormSubmitButton extends React.Component<ContactFormSubmitButtonProps,ContactFormSubmitButtonState> {
  constructor(props) {
    super(props)

    this.state = {
      isHovered: false,
      isActive: false
    }
  }

  mouseEnter = () => {
    this.setState({isHovered: true})
  }

  mouseLeave = () => {
    this.setState({isHovered: false, isActive: false})
  }

  mouseDown = () => {
    this.setState({isActive: true})
  }

  mouseUp = () => {
    this.setState({isActive: false})
  }

  render = () => {
    return (
      <div
        className="field has-addons composite-button"
        onClick={this.props.onClick}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onMouseDown={this.mouseDown}
        onMouseUp={this.mouseUp}>
        <div className="control">
          <span
            className={classNames(
              'button',
              'is-primary',
              {'is-loading': this.props.isSubmitting,
               'is-hovered': this.state.isHovered || this.state.isActive,
               'is-active': this.state.isActive})}>
            <span className="icon">
              <i className="fa fa-envelope"></i>
            </span>
          </span>
        </div>
        <div className="control">
          <button className={
              classNames(
                'button',
                'is-primary',
                {'is-hovered': this.state.isHovered || this.state.isActive,
                 'is-active': this.state.isActive})}>
              Send
          </button>
        </div>
      </div>)
  }
}

export default ContactFormSubmitButton