import * as React from 'react'
import { Collection } from 'immutable'
import classNames from 'classnames'

type LabeledErroredTextInputProps = {
  fieldName: string,
  labelText: string,
  valueGetter: (string) => string,
  changeHandler: (SyntheticEvent<HTMLInputElement>) => void,
  blurHandler: (SyntheticEvent<HTMLInputElement>) => void,
  getErrors: (string) => Collection.Set<string>,
  type: 'text' | 'textarea'
};

const FieldErrors = (props: {|errors: (Array<string> | Collection.Set<string>)|}) => {
  const {errors} = props

  return <p className="help is-danger">&#x200b;{errors.join(', ')}</p>
}

const LabeledErroredTextInput = (props: LabeledErroredTextInputProps) => {
  const {
    fieldName,
    labelText,
    valueGetter,
    changeHandler,
    blurHandler,
    getErrors,
    type,
    ...rest} = props

  let InputControl, inputProps

  if (type == 'text') {
    InputControl = 'input'
    inputProps = {type: type, ...rest}
  } else {
    InputControl = 'textarea'
    inputProps = {rows: 6, ...rest}
  }

  const fieldErrors = getErrors(fieldName)
  const hasErrors = fieldErrors.count() > 0

  return (
    <div className="field">
      {/*<label className="label">{labelText}</label>*/}
      <div className="control">
        <InputControl
          name={fieldName}
          className={classNames(InputControl, {'is-danger': hasErrors})}
          value={valueGetter(fieldName)}
          onChange={changeHandler}
          onBlur={blurHandler}
          {...inputProps} />
      </div>
      <FieldErrors errors={fieldErrors} />
    </div>)
}

LabeledErroredTextInput.defaultProps = {
  type: 'text'
}

export default LabeledErroredTextInput