import React from "react";
import Logo from '../utils/logo'
import ContactForm from '../utils/contactform'

import iconEmpireState from "../pages/icon-empire-state.svg"
import iconFlorida from "../pages/icon-florida.svg"

import { graphql } from 'gatsby'

if (!process.env.API_ENDPOINT) {
  throw new Error('process.env.API_ENDPOINT is unset')
} else {
  var apiEndpoint = process.env.API_ENDPOINT
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <div className="">
      <div className="banner hero is-info">
        {/* Navbar */}
        <div className="hero-body">
          <div className="container navbar">
            <div className="navbar-brand">
              <a className="navbar-item" href="/"><Logo className="logo" /></a>
            </div>
          </div>
        </div>
      </div>

      <section className="container content">
        <div className="columns">
          <div className="column">
            <h1>{frontmatter.title}</h1>
            <p>Effective Date: {frontmatter.date}</p>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </section>

      <section className="hero is-dark contact">
        {/* Contact */}
        <a name="contactus"/>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-2 locations">
                <div className="contactus-h1 is-uppercase">Locations</div>
                <div className="is-size-7">
                  <img src={iconEmpireState}/>
                  <span>New York City</span>
                </div>
                <div className="is-size-7">
                  <img src={iconFlorida}/>
                  <span>Jacksonville</span>
                </div>
              </div>

              <div className="column is-2 contact-methods">
                <div className="contactus-h1 is-uppercase">Contact Us</div>
                <div className="is-size-7">
                  <span className="icon">
                    <i className="fa fa-envelope"></i>
                  </span>
                  <a href="mailto:contact@heavyset.io">contact@heavyset.io</a>
                </div>
                <div className="is-size-7">
                  <span className="icon">
                    <i className="fa fa-phone"></i>
                  </span>
                  <a href="tel:1-929-324-7227">(929) 324-7227</a>
                </div>
              </div>

              <div className="column is-8">
                <div className="contactus-h1 is-uppercase">Message Us</div>
                <ContactForm
                  apiBaseURL={apiEndpoint}
                  inquiryPath="/inquiries" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export const pageQuery = graphql`
  query MarkdownPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
