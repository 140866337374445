import * as React from 'react'
import { Map as ImmutableMap, Set as ImmutableSet} from 'immutable'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'

import ContactFormSubmitButton from './contactsubmit'
import LabeledErroredTextInput from './labeledinput'

type AttributeValid = [true, null];
type AttributeInvalid = [false, string];
type ValidatorResult = AttributeValid | AttributeInvalid;
type ValidatorFunc = (string) => ValidatorResult;

const isBlank = (str: string): boolean => {
  return str.match(/^\s*$/) !== null
}

const validateEmail = (value: string): ValidatorResult => {
  if (!isBlank(value) && isEmail(value)) {
    return [true, null]
  } else {
    return [false, 'Must be a valid email address']
  }
}

const validatePresence = (value: string): ValidatorResult => {
  if (!isBlank(value)) {
    return [true, null]
  } else {
    return [false, 'Must not be blank']
  }
}

type ContactFormProps = {
  apiBaseURL: string,
  inquiryPath: string
};

type ContactFormState = {
  errors: ImmutableMap<string, ImmutableSet<string>>,
  fields: ImmutableMap<string, string>,
  submissionState: 'READY' | 'SUBMITTING' | 'SUBMITTED' | 'FAILED'
};

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  validators: ImmutableMap<string, ImmutableSet<ValidatorFunc>> = ImmutableMap()
  axios: Object

  constructor(props) {
    super(props)

    this.addValidator('email', validateEmail)
    this.addValidator('name', validatePresence)
    this.addValidator('message', validatePresence)

    this.state = {
      errors: ImmutableMap(),
      fields: ImmutableMap(),
      submissionState: 'READY'
    }

    this.axios = axios.create({
      baseURL: this.props.apiBaseURL
    })
  }

  addError = (attr: string, error: string): void => {
    this.setState((prevState) => ({
      errors: prevState.errors.update(
        attr,
        (errorSet = ImmutableSet()) => errorSet.add(error))
      }))
  }

  getErrors = (attr: string): ImmutableSet<string> => {
    return this.state.errors.get(attr) || ImmutableSet()
  }

  clearErrors = (attr: string): void => {
    this.setState((prevState) => {
      const newErrors = prevState.errors.set(attr, ImmutableSet())
      return {errors: newErrors}
    })
  }

  addValidator = (fieldName: string, validatorFunc: ValidatorFunc) => {
    this.validators = this.validators.update(fieldName, (v = ImmutableSet()) => {
      return v.add(validatorFunc)
    })
  }

  runValidators = (fieldName: string): number => {
    this.clearErrors(fieldName)

    return this.validators.get(fieldName, []).reduce((errorCount, validator) => {
      let [isValid, errorMessage] = validator(this.getFieldValue(fieldName))
      if (!isValid && errorMessage) {
        this.addError(fieldName, errorMessage)
        return errorCount + 1
      } else {
        return errorCount
      }
    }, 0)
  }

  runAllValidators = (): ImmutableMap<string, number> => {
    const errorCounts = this.validators.map((_, fieldName) => {
      return this.runValidators(fieldName)
    })

    return errorCounts
  }

  getFieldValue = (fieldName: string): string => {
    return this.state.fields.get(fieldName, '')
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name  = event.currentTarget.name,
          value = event.currentTarget.value

    this.setState((prevState) => {
      return {fields: prevState.fields.set(name, value)}
    })
  }

  validateField = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.currentTarget.name
    this.runValidators(name)
  }

  handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.submitForm()
  }

  submitForm = () => {
    const errorCount = this.runAllValidators().reduce((sum, c) => sum + c, 0)
    if (errorCount > 0) {
      return
    }

    this.setState({submissionState: 'SUBMITTING'})

    this.axios.post(
      this.props.inquiryPath,
      {inquiry: this.state.fields.toObject()}
    )
    .then((response) => {
      this.setState({submissionState: 'SUBMITTED'})
    })
    .catch((error) => {
      this.setState({submissionState: 'FAILED'})
    })
  }

  render = () => {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="columns">
          <div className="column is-4">
            <LabeledErroredTextInput
              fieldName="name"
              labelText="Name"
              placeholder="Jane Doe"
              valueGetter={this.getFieldValue}
              changeHandler={this.handleChange}
              blurHandler={this.validateField}
              getErrors={this.getErrors} />
            <LabeledErroredTextInput
              fieldName="company"
              labelText="Company"
              placeholder="Company (optional)"
              valueGetter={this.getFieldValue}
              changeHandler={this.handleChange}
              blurHandler={this.validateField}
              getErrors={this.getErrors} />
            <LabeledErroredTextInput
              fieldName="email"
              labelText="Email"
              placeholder="jane.doe@example.com"
              valueGetter={this.getFieldValue}
              changeHandler={this.handleChange}
              blurHandler={this.validateField}
              getErrors={this.getErrors} />
          </div>

          <div className="column is-6">
            <LabeledErroredTextInput
              type="textarea"
              fieldName="message"
              labelText="Message"
              placeholder="Message"
              valueGetter={this.getFieldValue}
              changeHandler={this.handleChange}
              blurHandler={this.validateField}
              getErrors={this.getErrors} />
          </div>

          <div className="column is-6">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ContactFormSubmitButton
                    onClick={this.submitForm}
                    isSubmitting={this.state.submissionState === 'SUBMITTING'} />
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  {this.state.submissionState === 'SUBMITTED' && <p>Message sent.</p>}
                  {this.state.submissionState === 'FAILED' && <p>An error occurred.</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>)
  }
}

export default ContactForm
