import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import './index.sass'

const Template = ({ children }) => (
  <div>
    <Helmet
      title="HeavySet"
      meta={[
        { name: 'description', content: 'HeavySet' },
        { name: 'keywords', content: 'software, consulting, heavyset' },
    ]}>
      <link href="https://fonts.googleapis.com/css?family=Raleway:400,600,700,900" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Roboto:400,600,700,900" rel="stylesheet" />
  	  <link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700" rel="stylesheet" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    </Helmet>

    {children}
  </div>
)

Template.propTypes = {
  children: PropTypes.func,
}

export default Template
